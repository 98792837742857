"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteResource = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const SiteLinkResource = typebox_1.Type.Object({
    attributes: model_helpers_1.JsonObject,
    href: typebox_1.Type.String(),
    rel: typebox_1.Type.String(),
});
const SiteScriptResource = typebox_1.Type.Object({
    src: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
});
exports.SiteResource = typebox_1.Type.Union([SiteLinkResource, SiteScriptResource]);
